'use client';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuthContext } from './auth';
import {
  GetOnboardingStateResponse,
  OnboardingState,
} from '@/types/onboarding';
import apiInstance from '@/api-requests/api-instance';
import { useQuery } from '@tanstack/react-query';
import { datadogRum } from '@datadog/browser-rum';
import { ToastContainer, toast } from 'react-toastify';
import { useRouter } from 'next/navigation';
import jwt_decode from 'jwt-decode';
import { getAppConfig } from '@/config';
import { AppConfig } from '@/config/types';
import { defaultConfig } from '@/config/default';

export interface Address {
  city: string;
  country: string;
  state: string;
  street: string;
  street2?: string;
  zip_code: string;
}

interface IOnboardingStore<T extends OnboardingState> {
  applicationId?: string | null;
  onboardingState?: T;
  setOnboardingState: Dispatch<SetStateAction<T | undefined>>;
  isLoading: boolean;
  navigateToPath: (path?: string) => void;
  brandConfig: AppConfig;
}

const OnboardingContext = createContext<IOnboardingStore<any>>({
  brandConfig: defaultConfig,
  isLoading: true,
  navigateToPath: () => {},
  setOnboardingState: () => {},
});

export const useOnboardingContext = <T extends OnboardingState>() =>
  useContext<IOnboardingStore<T>>(OnboardingContext);

interface Props {
  children: ReactNode;
  initialState?: Partial<OnboardingState>;
}

export const OnboardingProvider = ({ children, initialState }: Props) => {
  const { accessToken } = useAuthContext();
  const { push } = useRouter();

  const [onboardingState, setOnboardingState] = useState<
    Partial<OnboardingState> | undefined
  >(initialState);

  const [brandConfig, setBrandConfig] = useState<AppConfig>(defaultConfig);

  const { isLoading, data, error } = useQuery({
    enabled: !!accessToken,
    queryFn: async () => {
      const { data } =
        await apiInstance.get<GetOnboardingStateResponse<OnboardingState>>(
          '/v0/onboarding/',
        );
      return data;
    },
    queryKey: ['onboardingData'],
  });

  useEffect(() => {
    if (data?.state) {
      setOnboardingState(data.state);

      datadogRum.setUser({
        business_id: data.state.business_id,
        client_id: data.state.client_id,
      });
    }
  }, [data]);

  useEffect(() => {
    if (accessToken) {
      try {
        const accessTokenDecoded: any = jwt_decode(String(accessToken));

        const config = getAppConfig(accessTokenDecoded?.client_id ?? '');
        setBrandConfig(config);

        Object.keys(config.theme).map((key) => {
          if (document)
            document.documentElement.style.setProperty(
              `--${key}`,
              // it was either the weird snippet below or a TS error :(
              config.theme[key as keyof typeof config.theme] || '',
            );
        });
      } catch {
        datadogRum.addError(
          new Error('unable to decode jwt, failed to load custom brand theme'),
        );
      }
    }
  }, [accessToken]);

  useEffect(() => {
    if (error?.message) toast.error(error.message);
  }, [error]);

  const navigateToPath = useCallback(
    (path?: string) => {
      if (path && data) {
        push(`/${data.state.flow_name}/${data.id}/${path}`);
      }
    },
    [data, push],
  );

  return (
    <OnboardingContext.Provider
      value={{
        applicationId: data?.id,
        brandConfig,
        isLoading,
        navigateToPath,
        onboardingState,
        setOnboardingState,
      }}
    >
      {children}
      <ToastContainer
        autoClose={3000}
        hideProgressBar
        position="bottom-left"
        theme="colored"
      />
    </OnboardingContext.Provider>
  );
};
