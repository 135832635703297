import { AppConfig } from './types';
// ---------------------------------------------------
//             DEFAULT BOND CONFIG COLOR
// ---------------------------------------------------
export const defaultConfig: AppConfig = {
  programDisplayName: 'commercial secured card',
  support: {
    email: 'support@atelio.com',
    phone: '1-917-666-6666',
  },
  theme: {
    'action-button-disabled-color': '#C7D2FE',
    'action-button-hover-color': '#4338CA',
    'action-button-hover-text-color': '#FFFFFF',
    'action-button-text-color': '#FFFFFF',
    'action-color': '#4F46E5',
    'primary-brand-color': '#4F46E5',
  },
};
