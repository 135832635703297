import { useRouter } from 'next/router';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { configureApiInterceptors } from './configure-api-interceptors';
import { saveStorageTokens } from './save-storage-tokens';

interface Props {
  children: ReactNode;
}

interface IAuthStore {
  accessToken?: string | null;
}

const AuthContext = createContext<IAuthStore>({});

export const useAuthContext = () => useContext<IAuthStore>(AuthContext);

export const AuthProvider = ({ children }: Props) => {
  const [accessToken, setAccessToken] = useState<string | null>();
  const [_, setRefreshToken] = useState<string | null>();
  const [isInitialized, setIsInitialized] = useState(false);

  const { isReady, query } = useRouter();

  useEffect(() => {
    if (!accessToken && isReady) {
      const _accessToken = query?.access_token
        ? String(query.access_token)
        : window.localStorage.getItem('access_token');
      setAccessToken(_accessToken);

      const _refreshToken = query?.refresh_token
        ? String(query.refresh_token)
        : window.localStorage.getItem('refresh_token');
      setRefreshToken(_refreshToken);

      configureApiInterceptors(_accessToken);
      saveStorageTokens(_accessToken, _refreshToken);

      setIsInitialized(true);
    }
  }, [
    accessToken,
    isReady,
    query?.access_token,
    query?.refresh_token,
    setAccessToken,
  ]);

  if (!isInitialized) {
    return <></>;
  }

  if (!accessToken) {
    return <div id="missing">Need access token</div>;
  }

  return (
    <AuthContext.Provider value={{ accessToken }}>
      {children}
    </AuthContext.Provider>
  );
};
