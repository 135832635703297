import { AppConfig } from './types';

const royalpaySupport = {
  email: 'support@royalpay.com',
  phone: '1-800-XXX-XXXX',
};

// BOOST Payfac
export const boostConfig: AppConfig = {
  programDisplayName: 'commercial secured card',
  support: royalpaySupport,
  theme: {
    'action-button-disabled-color': '#ACACAC',
    'action-button-hover-color': '#DAF3E6',
    'action-button-hover-text-color': '#000000',
    'action-button-text-color': '#000000',
    'action-color': '#48C585', // maps to old interaction color
    'primary-brand-color': '#48C585', // maps to old action color
  },
};

// Inksoft
export const inksoftConfig: AppConfig = {
  programDisplayName: 'commercial secured card',
  support: royalpaySupport,
  theme: {
    'action-button-disabled-color': '#ACACAC',
    'action-button-hover-color': '#DAF3E6',
    'action-button-text-color': '#000000',
    'action-color': '#DAF3E6', // maps to old interaction color
    'primary-brand-color': '#48C585', // maps to old action color
  },
};

// Payrix
export const payrixConfig: AppConfig = {
  programDisplayName: 'commercial secured card',
  support: royalpaySupport,
  theme: {
    'action-button-disabled-color': '#ACACAC',
    'action-button-hover-color': '#DCECD7',
    'action-button-hover-text-color': '#000000',
    'action-button-text-color': '#000000',
    'action-color': '#51A63B', // maps to old interaction color
    'primary-brand-color': '#51A63B', // maps to old action color
  },
};

// Printavo
export const printavoConfig: AppConfig = {
  programDisplayName: 'commercial secured card',
  support: royalpaySupport,
  theme: {
    'action-button-disabled-color': '#ACACAC',
    'action-button-hover-color': '#DCECD7',
    'action-button-text-color': '#000000',
    'action-color': '#DCECD7', // maps to old interaction color
    'primary-brand-color': '#51A63B', // maps to old action color
  },
};
